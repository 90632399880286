import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Typography, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  tagDisplay: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
    marginBottom: "50px",
  },
  tagTitle: {},
  tagContent: { "& > a": { color: theme.palette.common.white } },
}))

const TagDisplay = props => {
  const classes = useStyles()
  const { intl, title, content } = props

  return (
    <Box className={classes.tagDisplay}>
      <Typography className={classes.tagTitle} variant="h4">
        {intl.formatMessage({ id: `${title}` })}
      </Typography>
      {content instanceof Array ? (
        content.map((content, index) => (
          <div key={index}>
            <Typography className={classes.tagContent} variant="body1">
              {content}
            </Typography>
          </div>
        ))
      ) : (
        <Typography className={classes.tagContent} variant="body1">
          {content}
        </Typography>
      )}
    </Box>
  )
}

export default injectIntl(TagDisplay)
